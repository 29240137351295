import React from 'react';
import ArticleBody from './ArticleBody';
import ArticleHero, { textColorTypes } from './ArticleHero';
import ArticleImage from './ArticleImage';
import ArticleParallax from './ArticleParallax';
import ArticleTable from './ArticleTable';
import ArticleGallery from './ArticleGallery';

export default function Article({ data }) {
  return (
    <>
      {data.articleSections?.map((section, index) => {
        switch (section.__typename) {
          case 'ContentfulArticleBody': {
            return <ArticleBody key={index} heading={section.heading} body={section.body} />;
          }
          case 'ContentfulArticleHero': {
            return (
              <ArticleHero
                key={index}
                heading={section.heading}
                image={section.image}
                body={section.body}
                textColor={textColorTypes.black}
              />
            );
          }
          case 'ContentfulArticleGallery': {
            return <ArticleGallery key={index} slides={section.images} heading={section.heading} />;
          }
          case 'ContentfulArticleParalax': {
            return (
              <ArticleParallax
                key={index}
                parallaxImage={section.stickyImage}
                textPosition={section.textPosition}
                scrollyText={section.scrollyText}
              />
            );
          }
          case 'ContentfulArticleTable': {
            return (
              <ArticleTable key={index} heading={section.heading} body={section.body} table={section.table?.table} />
            );
          }
          case 'ContentfulImage': {
            return (
              <ArticleImage
                key={index}
                heading={section.heading}
                image={section.image}
                credits={section.credits}
                description={section.description}
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
}
