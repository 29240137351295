import Section from '../../generic/Section';
import React from 'react';
import RichText from '../../generic/RichText';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';

/**
 * @param {object} props
 * @param {string} props.heading
 * @param {import('types').RichText} props.body
 * @param {Array<any>} props.table
 * @param {React.ComponentProps<typeof Section>['size']} [props.sectionSize]
 * @param {string} [props.className]
 * @param {string} [props.innerClassName]
 * @param {boolean} [props.firstColumnIsHeader]
 */
export default function ArticleTable({
  heading,
  body,
  table,
  sectionSize = 'medium',
  className = '',
  innerClassName = '',
  firstColumnIsHeader = true,
}) {
  // const filteredTable = useMemo(() => {
  //   return table.map(row => {
  //     return row.map(cell => {
  //       return cell;
  //     });
  //   });
  // }, [table]);

  return (
    <Section
      size={sectionSize}
      className={mergeClassNames(styles.articleTable, className)}
      innerClassName={mergeClassNames(styles.inner, innerClassName)}
    >
      <div className={styles.head}>
        {heading && <h4>{heading}</h4>}
        {body && <RichText>{body}</RichText>}
      </div>
      <table className={firstColumnIsHeader ? styles.firstColumnIsHeader : ''}>
        <thead>
          <tr>
            {table[0]?.map((cell, i) => {
              return <th key={i}>{cell}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {table.map((row, index) => {
            if (index === 0) return null;
            return (
              <tr key={index}>
                {row.map((cell, i) => {
                  if (firstColumnIsHeader && i === 0) {
                    return <th key={i}>{cell}</th>;
                  }
                  return <td key={i}>{cell}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Section>
  );
}
