import React from 'react';
import { mergeClassNames } from '../../../utils';
import Icon, { IconType } from '../Icon';
import styles from './index.module.scss';

interface IconButtonProps {
  IconType: IconType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ariaLabel: string;
  className?: string;
  disabled?: boolean;
}

export default function IconButton({
  IconType,
  onClick,
  ariaLabel,
  className = '',
  disabled = false,
}: IconButtonProps) {
  return (
    <button
      className={mergeClassNames(styles.iconButton, className)}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      <Icon IconType={IconType} />
    </button>
  );
}
