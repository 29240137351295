import { AnimationType, ImageContainerSlidePhase, getPosition } from '../ImageContainer';
import { Slide } from '../Gallery';
import React, { useRef } from 'react';
import { mediaQueries, universalMargin } from 'styles';
import styled, { css } from 'styled-components';

import RichText from '../../generic/RichText';
import { Slides } from '..';
import { grey50 } from 'styles/baseColors';

interface TextContainerProps {
  slides: Slides;
  activeSlideIndex: number;
  className?: string;
  showHeading?: boolean;
  sideBySide?: boolean;
  animation?: AnimationType;
  size?: {
    minHeight: '100px' | '200px' | '300px';
  };
  mutedBodyColor?: boolean;
}

export default function TextContainer({
  slides,
  activeSlideIndex,
  className,
  showHeading = true,
  sideBySide = false,
  size = { minHeight: '300px' },
  ...props
}: TextContainerProps) {
  return (
    <ListContainer className={className} style={size}>
      {slides.map((slide, index) => (
        <TextCard
          slide={slide}
          key={index}
          index={index}
          activeSlideIndex={activeSlideIndex}
          showHeading={showHeading}
          sideBySide={sideBySide}
          mutedBodyColor={props.mutedBodyColor}
          animation={props.animation}
        />
      ))}
    </ListContainer>
  );
}

const ListContainer = styled.ul`
  position: relative;
  height: auto;
`;

interface TextCardProps {
  slide: Slide;
  index: number;
  activeSlideIndex: number;
  className?: string;
  showHeading?: boolean;
  sideBySide?: boolean;
  animation?: AnimationType;
  size?: {
    minHeight: string;
  };
  mutedBodyColor?: boolean;
}

function TextCard({ slide, index, activeSlideIndex, showHeading, sideBySide = false, ...props }: TextCardProps) {
  const ref = useRef(null);
  return (
    <ListCardElement
      ref={ref}
      key={index}
      phase={getPosition(index, activeSlideIndex)}
      animation={props.animation || 'panAnimation'}
    >
      <TextWrapper sideBySide={sideBySide}>
        {showHeading && <Heading sideBySide={sideBySide}>{slide.heading}</Heading>}
        <Body mutedBodyColor={Boolean(props.mutedBodyColor)}>{'body' in slide ? slide.body : slide.description}</Body>
      </TextWrapper>
    </ListCardElement>
  );
}

const ListCardElement = styled.li<{ phase: ImageContainerSlidePhase; animation: AnimationType }>`
  overflow: hidden;
  transition: opacity 300ms ease-in, transform 300ms ease-in;
  top: 0;
  height: 0;
  opacity: 0;
  ${props => {
    switch (props.phase) {
      case 'predecessor':
        return css`
          transform: translateY(${props.animation === 'panAnimation' ? '-30px' : '0px'});
          opacity: 0;
        `;
      case 'current':
        return css`
          opacity: 1;
          transform: translateY(0px);
          height: auto;
        `;
      case 'successor':
        return css`
          transform: translateY(${props.animation === 'panAnimation' ? '30px' : '0px'});
          opacity: 0;
        `;
    }
  }}
`;

const Heading = styled.h4<{ sideBySide: boolean }>`
  ${props =>
    props.sideBySide
      ? css`
          padding: 0 ${universalMargin * 3}px ${universalMargin * 2}px;
          ${mediaQueries.medium(css`
            padding: 0 ${universalMargin * 3}px 0 0;
            min-height: unset;
          `)}
        `
      : ``}
`;

const Body = styled(RichText)<{ mutedBodyColor: boolean }>`
  ${props => (props.mutedBodyColor ? `color: ${grey50};` : '')}
`;

const TextWrapper = styled.div<{ sideBySide: boolean }>`
  > ${Body} {
    ${props =>
      props.sideBySide
        ? css`
            padding: 0 ${universalMargin * 3}px;
            > p:last-of-type,
            > p:first-of-type {
              padding: 0;
            }
            ${mediaQueries.medium(css`
              padding-right: ${universalMargin * 14}px;
            `)}
            ${mediaQueries.large(css`
              padding-right: ${universalMargin * 24}px;
            `)}
          `
        : ``}
  }
  ${props =>
    props.sideBySide
      ? css`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          ${mediaQueries.medium(css`
            flex-wrap: nowrap;
          `)}
          ${mediaQueries.large(css`
            margin: ${universalMargin * 7}px 0;
          `)}
        `
      : ``}
`;
