import React, { useState } from 'react';
import Section from '../../generic/Section';

import { ImageContainer } from '../../Gallery/ImageContainer';
import PrevNextControls from '../../Gallery/PrevNextControls';
import TextContainer from '../../Gallery/TextContainer';
import { mergeClassNames } from '../../../utils/mergeClassnames';
import styles from './index.module.scss';

export default function ArticleGallery({ slides = [], heading = null, className = null, style = null }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  return (
    <Section innerClassName={mergeClassNames(styles.articleGallery, className)} style={style} size="large">
      {heading && <h4>{heading}</h4>}
      <ImageContainer slides={slides} activeSlideIndex={activeSlideIndex} className={styles.imagePosition} />
      <PrevNextControls
        className={styles.prevNext}
        slides={slides}
        currentSlideNum={activeSlideIndex}
        onChange={setActiveSlideIndex}
      />
      <TextContainer
        slides={slides}
        activeSlideIndex={activeSlideIndex}
        className={styles.textContainer}
        sideBySide={true}
      />
    </Section>
  );
}
