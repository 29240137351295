import Section from 'components/generic/Section';
import { Row, Column } from 'components/generic/Grid';
import Image from 'components/generic/Image';
import React from 'react';
import RichText from 'components/generic/RichText';
import { mergeClassNames } from 'utils';
import styles from './index.module.scss';
import styled from 'styled-components';

export const textColorTypes = {
  white: 'white',
  black: 'black',
};

export default function ArticleHero({
  heading,
  body,
  image,
  eagerImageLoad = false,
  textColor = textColorTypes.black,
  id = '',
  className = '',
}) {
  return (
    <Section id={id} className={className} size="max" innerPadding>
      <Row>
        <Column tablet="10/12" tabletOffset="2/12" desktop="9/12" desktopOffset="3/12">
          <div>
            <Image
              image={image?.gatsbyImageData}
              objectFit="cover"
              objectPosition="center center"
              alt={image?.description}
              eager={eagerImageLoad}
              shape={'roundedCorners'}
            />
          </div>
        </Column>
      </Row>
      <div className={mergeClassNames(styles.content)}>
        <Row>
          <StyledColumn tablet="3/12" tabletOffset="2/12" className={textColor}>
            <h3 className={styles.heading}>{heading}</h3>
          </StyledColumn>
          <StyledColumn mobile="10/12" mobileOffset="2/12" desktop="6/12" desktopOffset="6/12" className={textColor}>
            {body ? <RichText className={styles.body}>{body}</RichText> : <p className={styles.body} />}
          </StyledColumn>
        </Row>
      </div>
    </Section>
  );
}

const StyledColumn = styled(Column)`
  &.white {
    color: white;
  }
  &.black {
    color: black;
  }
`;
