import Section from '../../generic/Section';

import React from 'react';
import RichText from '../../generic/RichText';
import styles from './index.module.scss';

export default function ArticleBody({ heading, body }) {
  return (
    <Section size="small" className={styles.articleBody}>
      {heading && <h4>{heading}</h4>}
      <RichText linkIcons>{body}</RichText>
    </Section>
  );
}
