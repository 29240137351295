import React, { useRef } from 'react';
import Section from '../../generic/Section';
import { useAtTopOfViewport, useInViewport } from '../../../utils';

import RichText from '../../generic/RichText';
import { mergeClassNames } from '../../../utils';
import styles from './index.module.scss';

export default function ArticleParallax({ parallaxImage, scrollyText, textPosition }) {
  let intersectionRef = useRef(null);
  let bottomRef = useRef(null);
  let isIntersecting = useAtTopOfViewport(intersectionRef);
  let isAtBottom = useInViewport(bottomRef);

  return (
    <div className={styles.articleParallax} ref={intersectionRef}>
      <div
        className={mergeClassNames(styles.image, isIntersecting ? (isAtBottom ? styles.isAtBottom : styles.fixed) : '')}
        style={{ backgroundImage: `url(${parallaxImage.file.url})` }}
      />

      {scrollyText.map((text, index) => (
        <Section key={index} className={styles.page} size="max">
          <div className={mergeClassNames(styles.box, styles[textPosition])}>
            <h4 className={styles.heading}>{text.heading}</h4>
            <RichText>{text.body}</RichText>
          </div>
        </Section>
      ))}
      <div ref={bottomRef} className={styles.bottom}></div>
    </div>
  );
}
