import React from 'react';
import RichText from '../../generic/RichText';
import { mergeClassNames } from '../../../utils';
import Image from '../../generic/Image';
import Section from '../../generic/Section';
import styles from './index.module.scss';

export default function ArticleImage({ heading, image, description, credits, className = null }) {
  return (
    <Section className={mergeClassNames(styles.articleImage, className)} size="medium">
      <div className={styles.inner}>
        {credits ? <div className={styles.credits}>{credits}</div> : null}
        <div className={styles.imageContainer}>
          <Image
            className={styles.image}
            image={image.gatsbyImageData}
            objectFit="cover"
            objectPosition="center center"
            alt={image.description}
          />
        </div>
        {heading ?? description ? (
          <div className={mergeClassNames(styles.content)}>
            {heading ? <h4 className={styles.heading}>{heading}</h4> : null}
            <RichText className={styles.description}>{description}</RichText>
          </div>
        ) : null}
      </div>
    </Section>
  );
}
