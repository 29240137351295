import React from 'react';
import { graphql } from 'gatsby';
import Article from '../components/Article/index.js';
import Head from '../components/Head';

export const query = graphql`
  query ($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      ...Article
    }
  }
`;

export default function ArticleWrapper({ location, data: { contentfulArticle } }) {
  return (
    <>
      <Head
        location={location}
        title={contentfulArticle.heading}
        description={contentfulArticle.description?.description || ''}
        image={contentfulArticle.thumbnail?.file?.url}
      />
      <Article data={contentfulArticle} />
    </>
  );
}
