import React from 'react';
import { mergeClassNames } from '../../../utils/mergeClassnames';
import { IconTypes } from '../../generic/Icon';
import IconButton from '../../generic/IconButton';
import styles from './index.module.scss';

interface PrevNextControlsProps {
  slides: Array<object>;
  currentSlideNum: number;
  className?: string;
  style?: object;
  onChange?: (index: number) => void;
}

/**
 * Prev Next Controls component
 *
 * Prev and next arrow buttons
 */
export default function PrevNextControls({
  slides,
  currentSlideNum,
  className,
  style,
  ...props
}: PrevNextControlsProps) {
  return (
    <div
      className={mergeClassNames(styles.prevNextControls, slides?.length <= 1 ? styles.disabled : null, className)}
      style={style}
    >
      <div className={styles.buttons}>
        <span className={styles.slideNumber}>{`${currentSlideNum + 1} of ${slides.length}`}</span>
        <IconButton
          IconType={IconTypes.left}
          onClick={() => {
            const index = getPrevNum(currentSlideNum, slides);
            if (props.onChange) props.onChange(index);
          }}
          ariaLabel="Previous slide"
          disabled={currentSlideNum <= 0}
        />
        <IconButton
          IconType={IconTypes.right}
          onClick={() => {
            const index = getNextNum(currentSlideNum, slides);
            if (props.onChange) props.onChange(index);
          }}
          ariaLabel="Next slide"
          disabled={currentSlideNum + 1 >= slides.length}
        />
      </div>
    </div>
  );
}

export function getPrevNum(currentSlideNum: number, slides: Array<object>) {
  if (slides.length < 1) return 0;
  if (currentSlideNum === 0) return slides.length - 1;
  return currentSlideNum - 1;
}

export function getNextNum(currentSlideNum: number, slides: Array<object>) {
  if (slides.length < 1) return 0;
  if (currentSlideNum >= slides.length - 1) return 0;
  return currentSlideNum + 1;
}
