import Image, { ImageProps } from '../../generic/Image';
import React from 'react';
import { mergeClassNames } from '../../../utils/mergeClassnames';
import styles from './index.module.scss';
import styled from 'styled-components';
import { Slides } from '..';

export type ImageStyle = { objectFit: 'cover' | 'contain'; objectPosition: 'center center' };
export type AnimationType = 'fadeAnimation' | 'panAnimation';

interface ImageContainerProps {
  slides: Slides;
  activeSlideIndex: number;
  className?: string;
  children?: React.ReactNode;
  animation?: AnimationType;
  objectFit?: ImageProps['objectFit'];
  objectPosition?: ImageProps['objectPosition'];
  eagerImageLoad?: boolean;
  roundedCorners?: boolean;
  imageHeight?: string;
}

// shared between the different Gallery types
export function ImageContainer({
  slides,
  activeSlideIndex,
  className,
  children,
  animation = 'panAnimation',
  objectFit,
  objectPosition,
  eagerImageLoad = false,
  imageHeight = '56.25%',
  ...props
}: ImageContainerProps) {
  return (
    <CarouselContainer height={imageHeight} className={mergeClassNames(styles[animation], className)}>
      {slides.map((slide, index) => {
        const image = ('image' in slide && slide.image) || ('images' in slide && slide.images?.[0]?.image);
        return (
          <li className={styles[getPosition(index, activeSlideIndex)]} key={`${slide?.id}-${index}`}>
            {image && (
              <StyledImage
                image={image.gatsbyImageData}
                objectFit={objectFit}
                objectPosition={objectPosition}
                className={mergeClassNames(styles.image)}
                eager={eagerImageLoad}
                shape={props.roundedCorners ? 'roundedCorners' : 'rectangular'}
                alt={image.description}
              />
            )}
            {children}
          </li>
        );
      })}
    </CarouselContainer>
  );
}

const CarouselContainer = styled.ul<{ height: string }>`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  &::after {
    content: '';
    width: 100%;
    display: table;
    padding-bottom: ${props => (props.height ? props.height : `56.25%`)};
  }
`;

const StyledImage = styled(Image)`
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain !important;
`;

export type ImageContainerSlidePhase = 'predecessor' | 'current' | 'successor' | 'single';
export function getPosition(slideIndex: number, activeSlideIndex: number): ImageContainerSlidePhase {
  if (slideIndex === activeSlideIndex) return 'current';
  if (slideIndex < activeSlideIndex) return 'predecessor';
  if (slideIndex > activeSlideIndex) return 'successor';
  return 'single'; //ever used?
}
